<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import autofit from "@/utils/autofit";

export default {
  data() {
    return {};
  },
  mounted() {
    // autofit.init(
    //   {
    //     designHeight: 1040,
    //     designWidth: 1920,
    //     renderDom: "#app",
    //     resize: true,
    //   },
    //   false
    // ); // 可关闭控制台运行提示输出
  },
};
</script>

<style lang="scss">
body {
  font-size: 16px;
  color: #000;
  background-color: #fff;
}
#app {
  max-width: 1920px;
  margin: 0 auto;
}
.banner-bg-area {
  min-width: 1400px;
  height: 1000px;
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 1900px) {
  .banner-bg-area {
    background-size: auto 100%;
  }
}
@media screen and (min-width: 2500px) {
  .banner-bg-area {
    background-size: 100% auto;
  }
}
.limit-width {
  width: 1400px;
  margin: 0 auto;
}
.min-width {
  min-width: 950px;
  margin: 0 auto;
}

@media screen and (max-width: 1405px) {
  .limit-width {
    width: 100%;
    padding-left: 20px !important;
    padding-right: 20px !important;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 950px) {
  .limit-width {
    width: 950px;
    box-sizing: border-box;
  }
}

.pagination-area {
  display: flex;
  justify-content: flex-end;
  padding: 60px 0;
  .el-pager li:hover,
  .el-pagination button:hover {
    color: #000;
  }
  .el-pager li,
  .el-pager li.btn-quicknext,
  .el-pager li.btn-quickprev {
    color: #ccc;
  }
  .el-pager li.active {
    color: #000;
  }
}

.block-image {
  display: block;
  width: 100%;
}

.f {
  display: flex;
}
.f-row-s-c {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
.f-row-c-c {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.f-row-b {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.f-row-b-c {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.i-f-row-c-c {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.i-f-row-b {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
}
/*
  滚动条样式修改
*/
// .scrollbar-area::-webkit-scrollbar {
//   /*滚动条整体样式*/
//   width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
//   height: 4px;
// }
// .scrollbar-area::-webkit-scrollbar-thumb {
//   /*滚动条里面小方块*/
//   border-radius: 5px;
//   background: #1050a5;
// }
</style>
